<template>
    <div class="content-wrapper">
        <div class="content-header">
            <div class="container-fluid">
                <div class="row mb-2">
                    <div class="col-sm-6">
                        <h1 class="m-0">Acompanhamento de Correções</h1>
                    </div>
                    <div class="col-sm-6">
                        <ol class="breadcrumb float-sm-right">
                            <li class="breadcrumb-item"><a href="#">Home</a></li>
                            <li class="breadcrumb-item active">Dashboard v1</li>
                        </ol>
                    </div>
                </div>
            </div>
        </div>
        <section class="content">
            <div class="card">
                <div class="card-body">
                    <div class="row">
                        <div class="col-md-12 order-2 order-md-1">
                            <div class="row">
                                <div class="col-12">
                                    <div class="post" v-for="accompaniment in accompaniments" :key="accompaniment.id">
                                        <div class="user-block">
                                            <img class="img-circle elevation-2" src="../../assets/logo.png">
                                            <span class="username">Redação Online</span>
                                            <span class="description">{{ new Date(accompaniment.created_at).toLocaleString() }}</span>
                                        </div>
                                        <span class="badge bg-green" v-if="accompaniment.correction.essay.corrections[0].id > accompaniment.correction_id">Recorreção Concluída</span>
                                        <span class="badge bg-yellow" v-else>Aguardando Recorreção</span>
                                        <p>{{ accompaniment.feedback }}</p>
                                        <p>
                                            <router-link :to="`/corrigir/${accompaniment.correction.essay_id_new}`" class="btn btn-sm btn-success">
                                                <font-awesome-icon icon="external-link-alt" /> Abrir correção
                                            </router-link>
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="card-footer">
                    <Pagination @updateData="accompaniments = $event" @isBusy="isBusy = $event" :url="url"/>
                </div>
            </div>
        </section>
    </div>
</template>

<script>
import Pagination from '../partials/Pagination.vue'
import { ACCOMPANIMENT_INDEX } from '../../constants/api'

export default {
    name: 'Index',
    components: { Pagination },
    data() {
        return {
            accompaniments: [
                {
                    id: null,
                    correction_id: null,
                    supervisor_id: null,
                    feedback: null,
                    created_at: null,
                    correction: {
                        id: null,
                        essay_id_new: null,
                        essay: {
                            id: null,
                            corrections: [
                                {
                                    id: null,
                                    essay_id_new: null
                                }
                            ]
                        }
                    }
                }
            ],
            url: ACCOMPANIMENT_INDEX,
        }
    },
}
</script>